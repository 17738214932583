import React from "react";
import AppStack from "./routes";

class App extends React.Component {

  render = () => {
    return (
      <AppStack />
    )
  }

}

export default App;