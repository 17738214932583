import React from "react";
import { Layout, Card, Image, Typography, Form, Input, Button, message } from "antd";
import { Link, Navigate } from 'react-router-dom';

import logo from '../assets/slog1.png';
import { POST_API, POST_CATCH } from "../services/api";

class Forgot extends React.Component {

    state = { _navigate: false, _loading: false }

    onSend = (values) => {

        this.setState({_loading: true})

        POST_API('/credential/forgot.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))

    }

    render = () => {

        if (this.state._navigate) return ( <Navigate to='dashboard' />)

        return (
            <Layout.Content className="login-content">
                <Card className="login-card">
                    <center><Image src={logo} preview={false} className="login-card-image"/></center>
                    <Typography className="login-card-title">Esqueceu a senha?</Typography>
                    <Form layout="vertical" onFinish={this.onSend}>
                        <Form.Item label="Matrícula ou e-mail" name="LOGIN" className="login-form-item">
                            <Input size="large" placeholder="Número da matrícula ou e-mail" />
                        </Form.Item>
                        <Form.Item className="login-form-item">
                            <Button htmlType="submit" type="primary" block className="btn-primary" shape="round" loading={this.state._loading}>Enviar e-mail para redefinir senha</Button>
                            <Link to="/"><Button type="link" block className="btn-link" shape="round">Voltar</Button></Link>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout.Content>
        )
    }

}

export default Forgot;