import React from "react";
import { Button, Col, Image, Layout, Modal, Row } from "antd";

import { IoHomeOutline, IoGridOutline, IoPersonOutline, IoLogOutOutline, IoSearchOutline, IoPeopleOutline, IoInformationCircleOutline, IoSchoolOutline, IoBusinessOutline } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../assets/slog1.png';
import { Link, Navigate, Outlet } from "react-router-dom";
import { POST_API, POST_CATCH, delToken, delType, getToken, getType } from "../services/api";
import FAQButton from "../components/FAQButton";

class Main extends React.Component {

    state = { _navigate: false, select: 'dashboard', paths: [], _user: null }

    componentDidMount = () => {

        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    this.setState({_navigate: true})
                } else {
                    this.setState({_user: res.user})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            this.setState({_navigate: true})
        }
        
        const params = window.location.href;
        const values = params.split('/');

        values[4] = values[4] ? values[4] : values[3]
        
        this.onSelect(values[4], false)

    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title: 'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() { self.setState({_navigate: true}); delToken(); delType() },
            onCancel() {},
        })
    }

    render = () => {

        if (this.state._navigate) return ( <Navigate to="/" />)

        return (
            <Layout.Content className="content">

                <Row>
                    <Col md={24} xs={0}>
                        <Row style={{flexWrap: 'nowrap'}}>
                            <Col flex={'200px'} className="sidebar-pc">
                                <Row>
                                    <Col span={24}><Image src={logo} preview={false} className="sibebar-image" /></Col>
                                    <Col span={24} className="bottom-nav"><Link to="/dashboard"><Button onClick={() => this.onSelect('dashboard')} shape="circle" type="link" className={ this.state.select === 'dashboard' ? 'btn-link-pc active' : 'btn-link-pc' }><IoHomeOutline size={20} style={{marginRight: 10}} /> Home</Button></Link></Col>
                                    { getType() === 'A' || getType() === 'RH' ? <Col span={24} className="bottom-nav"><Link to="consulta"><Button onClick={() => this.onSelect('consulta')} shape="circle" type="link" className={ this.state.select === 'consulta' ? 'btn-link-pc active' : 'btn-link-pc' }><IoSearchOutline size={20} style={{marginRight: 10}} /> Consulta</Button></Link></Col> : null }
                                    <Col span={24} className="bottom-nav"><Link to="timeline"><Button onClick={() => this.onSelect('timeline')} shape="circle" type="link" className={ this.state.select === 'timeline' ? 'btn-link-pc active' : 'btn-link-pc' }><IoGridOutline size={20} style={{marginRight: 10}} /> Timeline</Button></Link></Col>
                                    { getType() === 'A' ? <Col span={24} className="bottom-nav"><Link to="users"><Button onClick={() => this.onSelect('users')} shape="circle" type="link" className={ this.state.select === 'users' ? 'btn-link-pc active' : 'btn-link-pc' }><IoPeopleOutline size={20} style={{marginRight: 10}} /> Usuários </Button></Link></Col> : null }
                                    <Col span={24} className="bottom-nav"><Link to="profile"><Button onClick={() => this.onSelect('profile')} shape="circle" type="link" className={ this.state.select === 'profile' ? 'btn-link-pc active' : 'btn-link-pc' }><IoPersonOutline size={20} style={{marginRight: 10}} /> Meus Dados </Button></Link></Col>
                                    <Col span={24} className="bottom-nav"><Link to="info"><Button onClick={() => this.onSelect('info')} shape="circle" type="link" className={ this.state.select === 'info' ? 'btn-link-pc active' : 'btn-link-pc' }><IoInformationCircleOutline size={20} style={{marginRight: 10}} /> Informações </Button></Link></Col>
                                    <Col span={24} className="bottom-nav"><Link to="treinamento"><Button onClick={() => this.onSelect('treinamento')} shape="circle" type="link" className={ this.state.select === 'treinamento' ? 'btn-link-pc active' : 'btn-link-pc' }><IoSchoolOutline size={20} style={{marginRight: 10}} /> Treinamentos </Button></Link></Col>
                                    <Col span={24} className="bottom-nav"><Button onClick={this.onLogOut} shape="circle" type="link" className="btn-link-pc"><IoLogOutOutline size={20} style={{marginRight: 10}} /> Sair</Button></Col>
                                </Row>
                            </Col>
                            <Col flex={'auto'} className="main-pc">
                                <Outlet />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={0} xs={24}>
                        <Row gutter={[0,0]}>
                            <Col span={24} className="navbar">
                                <Image src={logo} preview={false} className="navbar-image" />   
                            </Col>
                            <Col span={24} className="main">
                                <Outlet />
                            </Col>
                            <Col span={24} className="bottombar">
                                <Row className="bottom" justify={'space-around'} align={'middle'}>
                                    <Col className="bottom-nav"><Link to="/dashboard"><Button onClick={() => this.onSelect('dashboard')} shape="circle" type="link" className={ this.state.select === 'dashboard' ? 'btn-link active' : 'btn-link' }><IoHomeOutline size={25} /></Button></Link></Col>
                                    { getType() === 'A' || getType() === 'RH' ? <Col className="bottom-nav"><Link to="consulta"><Button onClick={() => this.onSelect('consulta')} shape="circle" type="link" className={ this.state.select === 'consulta' ? 'btn-link-pc active' : 'btn-link-pc' }><IoSearchOutline size={25}/></Button></Link></Col> : null }
                                    <Col className="bottom-nav"><Link to="timeline"><Button onClick={() => this.onSelect('timeline')} shape="circle" type="link" className={ this.state.select === 'timeline' ? 'btn-link active' : 'btn-link' }><IoGridOutline size={25} /></Button></Link></Col>
                                    <Col className="bottom-nav"><Link to="profile"><Button onClick={() => this.onSelect('profile')} shape="circle" type="link" className={ this.state.select === 'profile' ? 'btn-link active' : 'btn-link' }><IoPersonOutline size={25} /></Button></Link></Col>
                                    { getType() === 'A' ? <Col className="bottom-nav"><Link to="users"><Button onClick={() => this.onSelect('users')} shape="circle" type="link" className={ this.state.select === 'users' ? 'btn-link active' : 'btn-link' }><IoPeopleOutline size={25} /></Button></Link></Col> : null }
                                    <Col className="bottom-nav"><Link to="info"><Button onClick={() => this.onSelect('info')} shape="circle" type="link" className={ this.state.select === 'info' ? 'btn-link active' : 'btn-link' }><IoInformationCircleOutline size={25} /></Button></Link></Col>
                                    <Col className="bottom-nav"><Link to="treinamento"><Button onClick={() => this.onSelect('treinamento')} shape="circle" type="link" className={ this.state.select === 'treinamento' ? 'btn-link active' : 'btn-link' }><IoSchoolOutline size={25} /></Button></Link></Col>
                                    <Col className="bottom-nav"><Button onClick={this.onLogOut} shape="circle" type="link"><IoLogOutOutline size={25} /></Button></Col>
                                </Row> 
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <FAQButton user={this.state._user} />
            </Layout.Content>
        )
    }

}

export default Main;