import React from "react";
import { Button, Card, Col, Input, Popover, Row, Typography, message, Spin, Select } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken } from "../../services/api";
import { IoArrowBack, IoArrowDown, IoArrowUp, IoClose } from "react-icons/io5";

class TreinamentoFormQuest extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        rows: [],
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = () => {
        if (this.props.type === 'edit') {
            POST_API('/training_question/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({TRAINING_CLASS_ID: this.state._params[8]}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({rows: res.data})
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
            POST_API('/training_class/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[8]}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    console.log(res.data)
                    this.setState({defaultValue: { ID: res.data.ID, TITLE: res.data.TITLE, TYPE_CLASS: 'Q', TRAINING_ID: this.state._params[5] } })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => setTimeout(this.setState({_screen: false}), 500))
        } else {
            this.setState({_screen: false, defaultValue: { TYPE_CLASS: 'Q', TRAINING_ID: this.state._params[5] }})
        }
    }

    onSend = () => {
        this.setState({_loading: true})
        POST_API('/training_question/save.php', { token: getToken(), master: JSON.stringify(this.state.rows), blog: JSON.stringify(this.state.defaultValue) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    onAdd = () => {
        this.setState({ rows: [ ...this.state.rows, { ASK: '', TYPE_ASK: '', OPTIONS_ASK: [], ANSWER: ''  } ] })
    }

    onChange = (row, field, value) => {

        var array = this.state.rows;

        if(value.hasOwnProperty("target")){
            array[row][field] = value.target.value
        } else {
            array[row][field] = value
        }

        this.setState({ rows: array })

    }

    onUpItem = (item) => {

        var array = this.state.rows;
        array.splice(item-1, 0, array.splice(item, 1)[0]);
        this.setState({ rows: array })

    }

    onDeleteItem = (item) => {

        var array = this.state.rows;
        array.splice(item, 1)
        this.setState({ rows: array })

    }

    onDownItem = (item) => {

        var array = this.state.rows;
        array.splice(item+1, 0, array.splice(item, 1)[0]);
        this.setState({ rows: array })

    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/treinamento/'+this.state._params[5]+'/class'} />)
        }

        return (
            <Row className="page-content" gutter={[16, 16]}>
                <Col span={24}>
                    <Card size="small">
                        <Row justify={'space-between'}>
                            <Col>
                                <Row gutter={[8,8]} align={'bottom'}>
                                    <Col><Popover content="Voltar"><Link to={'/dashboard/treinamento/'+this.state._params[5]+'/class'}><IoArrowBack size={20} className="tr-button"/></Link></Popover></Col>
                                    <Col><Typography className="tr-title">{ this.props.type === 'add' ? 'Adicionar' : 'Editar' } questionário</Typography></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                    <Col span={24} style={{marginTop: 10}}>
                        <Card>
                            <Row>
                                <Col span={24}>
                                    <Typography>Nome do questionário</Typography>
                                    <Input style={{marginBottom: 10}} placeholder="Nome do questionário" value={this.state.defaultValue?.TITLE} onChange={(valor) => this.setState({ defaultValue: { ...this.state.defaultValue, TITLE: valor.target.value } })} />
                                    <Typography>Perguntas</Typography>
                                    <Row gutter={[8,8]} style={{marginTop: 10}}>
                                        { this.state.rows.length === 0 ? <Col><Typography style={{fontSize: 10, textTransform: 'uppercase', color: 'rgba(0,0,0,0.4)'}}>Nenhuma pergunta cadastrada</Typography></Col> : this.state.rows.map((v, i) => (
                                            <Col key={i} span={24}>
                                                <div className="page-builder-row-select">
                                                    <Row gutter={[8,8]} style={{width: '100%'}}>
                                                        <Col md={4} xs={24}>
                                                            <Typography>Tipo de Pergunta</Typography>
                                                            <Select style={{width: '100%'}} value={v.TYPE_ASK} onChange={(valor) => this.onChange(i, 'TYPE_ASK', valor)}>
                                                                <Select.Option value="OPCAO">Opção</Select.Option>
                                                                <Select.Option value="ESCRITA">Escrita</Select.Option>
                                                            </Select>
                                                        </Col>
                                                        <Col md={20} xs={24}>
                                                            <Typography>Pergunta</Typography>
                                                            <Input value={v.ASK} onChange={(valor) => this.onChange(i, 'ASK', valor)}/>
                                                        </Col>
                                                        { v.TYPE_ASK === 'OPCAO' ? (
                                                            <Col md={16} xs={24}>
                                                                <Typography>Opções</Typography>
                                                                <Select style={{width: '100%'}} mode="tags" notFoundContent={null} value={v.OPTIONS_ASK} onChange={(valor) => this.onChange(i, 'OPTIONS_ASK', valor)} />
                                                            </Col>
                                                        ) : null}
                                                        { v.TYPE_ASK === 'OPCAO' ? (
                                                            <Col md={8} xs={24}>
                                                                <Typography>Resposta</Typography>
                                                                <Select allowClear style={{width: '100%'}} value={v.ANSWER} onChange={(valor) => this.onChange(i, 'ANSWER', valor)}>
                                                                    { v.OPTIONS_ASK.map((v1, i1) => <Select.Option value={v1} key={i1}>{v1}</Select.Option> ) }
                                                                </Select>
                                                            </Col>
                                                        ) : null}
                                                        { v.TYPE_ASK === 'ESCRITA' ? (
                                                            <Col md={24} xs={24}>
                                                                <Typography>Resposta</Typography>
                                                                <Input.TextArea value={v.ANSWER} onChange={(valor) => this.onChange(i, 'ANSWER', valor)} />
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                </div>
                                                <Row justify={'center'}>
                                                    {i !== 0 ? <Col><IoArrowUp onClick={() => this.onUpItem(i)} title="Mudar posição" size={16} className="close-page-builder" /></Col> : null }
                                                    <Col><IoClose onClick={() => this.onDeleteItem(i)} title="Deletar" size={16} className="close-page-builder" /></Col>
                                                    {i+1 !== this.state.rows.length ? <Col><IoArrowDown onClick={() => this.onDownItem(i)} title="Mudar posição" size={16} className="close-page-builder" /></Col> : null }
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                <Col span={24} style={{marginTop: 10}}>
                                    <Button loading={this.state._loading} onClick={this.onSend} type="primary" shape="round" style={{float: 'right'}}>Salvar</Button>
                                    <Button loading={this.state._loading} onClick={this.onAdd} type="default" shape="round" style={{float: 'right', marginRight: 10}}>Nova Pergunta</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ) }
            </Row>
        )

    }

}

export default TreinamentoFormQuest;