import React from "react";
import { Button, Col, Row, Spin, Typography, message } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken, getType } from "../services/api";


class Info extends React.Component {

    state = { _user: null, _loading: true }

    componentDidMount = () => {
        this.onView()
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({NAME: value}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    onView = () => {
        POST_API('/page/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({TYPE: this.props.page}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({
                    table_data: res.data,
                    table_pagination: {
                        ...this.state.table_pagination,
                        total: res.summary.QTDE
                    }
                })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false }))
    }

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/credential/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView() )
    }

    render = () => {
        
        if (this.props.page === 'P') {
            return (
                <Row className="page" gutter={[16, 16]} align={'middle'} justify={'center'}>
                    { getType() === 'A' || getType() === 'MK' ? (
                        <Col md={6} xs={12}>
                            <Link to="conffaq"><Button type="primary" block>Configurar FAQ</Button></Link>
                        </Col>
                    ) : null }
                    { getType() === 'A' || getType() === 'MK' ? (
                        <Col md={6} xs={12}>
                            <Link to="confpag"><Button type="primary" block>Configurar Páginas</Button></Link>
                        </Col>
                    ) : null }
                    { this.state._loading ? <Col span={24}><center><Spin /></center></Col> : (
                        <>
                            <Col md={24} xs={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Link to="faq"><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">FAQ</Typography></Button></Link>
                                    </Col>
                                    { this.state.table_data && this.state.table_data.map((v,i) => (
                                        <>
                                            {v.ID !== '6' && v.ID !== '7' ? (
                                                <Col span={24}>
                                                    <Link to={v.ID}><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">{v.TITLE}</Typography></Button></Link>
                                                </Col>
                                            ) : null}
                                        </>
                                    )) }
                                </Row>
                            </Col>
                        </>
                    ) }
                </Row>
            )
        }

        if (this.props.page === 'T') {
            return (
                <Row className="page" gutter={[16, 16]} align={'middle'} justify={'center'}>
                    { getType() === 'A' || getType() === 'MK' ? (
                        <Col md={6} xs={12}>
                            <Link to="conf"><Button type="primary" block>Configurar Treinamentos</Button></Link>
                        </Col>
                    ) : null }
                    { this.state._loading ? <Col span={24}><center><Spin /></center></Col> : (
                        <>
                            <Col md={24} xs={24}>
                                <Row gutter={[16, 16]}>
                                    { this.state.table_data && this.state.table_data.map((v,i) => (
                                        <>
                                            {v.ID !== '6' && v.ID !== '7' ? (
                                                <Col span={24}>
                                                    <Link to={v.ID}><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">{v.TITLE}</Typography></Button></Link>
                                                </Col>
                                            ) : null}
                                        </>
                                    )) }
                                </Row>
                            </Col>
                        </>
                    ) }
                </Row>
            )
        }
    }

}

export default Info;