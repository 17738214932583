import React from "react";
import { Col, Form, Row, Typography, Pagination, Table, Input, Spin, Divider, Button } from "antd";

import './styles.css';
import { Link } from "react-router-dom";
import { IoLockClosed, IoPencil, IoPeople, IoPerson, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";

class TableComponent extends React.Component {

    render = () => {

        const { _onRecover, _onAction, _border, _expandedRows, _columns, _data, _onChangeTable, _onSend, _loading, _onChangePagination, _pagination, _onChangeSearch, _search, _rowSelection } = this.props

        return (
            <Row gutter={[8,8]}>
                <Col xs={24} md={0}>
                    <Row className="table__row_table" gutter={[8,8]}>
                        <Col span={24}>
                            <Row gutter={[8,8]}>
                                <Col span={24} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <center><Typography>Total de {_pagination.total} registros retornados</Typography></center>
                                </Col>
                                <Col span={24}>
                                    <Form layout="vertical" onFieldsChange={_onSend}>
                                        <Form.Item name="search" className="input-search">
                                            <Input onChange={_onChangeSearch} placeholder={"Pesquisar..."}  className="table__form_filter_input" value={_search} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                { _loading ? <Col xs={24} md={0}><Row className="table__row_table" gutter={[8,8]} justify={'center'}><Spin /></Row></Col> : (
                    <>
                        { _data.length > 0 ? (
                            <>
                                { _data.map((v,i) => (
                                    <Col xs={24} md={0} key={i}>
                                        <Row className="table__row_table" gutter={[8,8]}>
                                            { _columns.map((vc, ic) => (
                                                <>
                                                    { vc.title !== 'Ações' ? (
                                                        <Col span={vc.table === '1/2' ? 12 : 24} key={ic} style={{marginBottom: 10}}>
                                                            <Typography className="item-title">{vc.title}</Typography>
                                                            <Typography className="item-text">{v[vc.dataIndex] ? v[vc.dataIndex] : '-'}</Typography>
                                                        </Col>
                                                    ) : (
                                                        <>
                                                            <Col span={24}><Divider style={{marginBottom: 5, marginTop: 5}} /></Col> 
                                                            <Col span={24}>
                                                                <Row justify={'center'} align={'middle'} gutter={[8,8]}>
                                                                    { v?.actions.map((va, ia) => {
                                                                        if (va === 'EDIT') return (<Col><Link to={v.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="middle"><IoPencil size={20} /></Button></Link></Col>)
                                                                        if (va === 'DELETE') return (<Col><Button onClick={() => _onAction(v.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="middle"><IoTrash size={20} /></Button></Col>)
                                                                        if (va === 'FORGOT') return (<Col><Button onClick={() => _onRecover(v.ID)} className="table__btn table__btn_view" title='Redefinir Senha' type="primary" shape="circle" size="middle"><IoLockClosed size={20} /></Button></Col>)
                                                                        if (va === 'RECOVER') return (<Col><Button onClick={() => _onAction(v.ID, 'rec', 'Recuperar registro?')} className="table__btn table__btn_recover" title='Recuperar' type="primary" shape="circle" size='middle'><IoReturnUpBackOutline size={20} /></Button></Col>)
                                                                        if (va === 'DIVIDER') return (<Col><Divider type="vertical" /></Col>)
                                                                        if (va === 'TYPE_CREDENTIAL') return (<Col><Link to={v.ID+'/tipousuario'}><Button className="table__btn table__btn_view" title='Tipos de Usuário' type="primary" shape="circle" size="middle"><IoPeople size={14} /></Button></Link></Col>)
                                                                        if (va === 'CREDENTIAL') return (<Col><Link to={v.ID+'/usuarios'}><Button className="table__btn table__btn_action_1" title='Usuários' type="primary" shape="circle" size="middle"><IoPerson size={14} /></Button></Link></Col>)
                                                                        return null;
                                                                    } ) }
                                                                </Row>
                                                            </Col>
                                                        </>
                                                    ) }
                                                </>
                                            )) }
                                        </Row>
                                    </Col>
                                )) }
                                {_pagination?.current > 0 ? (
                                    <Col xs={24} md={0}>
                                        <Row className="table__row_table" justify="center" gutter={[8,8]}>
                                            <Pagination size="small" current={_pagination.current} defaultCurrent={_pagination.current} total={_pagination.total} onChange={_onChangePagination} showSizeChanger/>
                                        </Row>
                                    </Col>
                                ) : null }
                            </>
                        ) : <Col xs={24} md={0}><Row className="table__row_table" gutter={[8,8]} justify={'center'}><Typography>Sem itens cadastrados</Typography></Row></Col>}
                    </>
                ) }
                <Col xs={0} md={24}>
                    <Row className="table__row_table" gutter={[8,8]}>
                        { _border ? null : (
                            <Col span={24}>
                                <Row>
                                    <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography className="table__summary">Total de {_pagination.total} registros retornados</Typography>
                                    </Col>
                                    <Col span={12}>
                                        <Form layout="vertical" onFieldsChange={_onSend} className="table__form_filter">
                                            <Form.Item name="search">
                                                <Input onChange={_onChangeSearch} placeholder={"Pesquisar..."}  className="table__form_filter_input" value={_search} />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        ) }
                        <Col span={24}>
                            <Table
                                size="small"
                                pagination={false}
                                onChange={_onChangeTable}
                                columns={_columns}
                                dataSource={_data} 
                                loading={_loading}
                                scroll={{x: true}}
                                expandable={_expandedRows}
                                className={_border? `table__ table__border` : `table__`}
                                rowSelection={_rowSelection}
                            />
                        </Col>
                        {_pagination?.current > 0 ? (
                            <Col span={24}>
                                <Row justify="end">
                                    <Pagination size="small" current={_pagination.current} defaultCurrent={_pagination.current} total={_pagination.total} onChange={_onChangePagination} showSizeChanger/>
                                </Row>
                            </Col>
                        ) : null }
                    </Row>
                </Col>
            </Row>
        )

    }
    
}

export default TableComponent;