import React from "react";
import { Col, Image, Row, Spin, Typography } from "antd";
import ReactPlayer from "react-player";

class TreinamentoPage extends React.Component {

    render = () => {
        return (
            <Row gutter={[8,8]}>
                <Col className="main-main" span={24} style={{padding: 10}}>
                    { this.props.screen ? <center><Spin /></center> : (
                        <Row gutter={[16,16]}>
                            <Col md={24} xs={24}>
                                { this.props.rows.map((v, i) => (
                                    <Row key={i} className="blog-page-row">
                                        { v.columns && v.columns.map((cv, ci) => (
                                            <Col key={ci} md={24/v.type} xs={24} className="blog-page-column">
                                                <Row>
                                                { cv.items.map((iv, ii) => (
                                                    <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                        { iv.type === 'T' ? (
                                                            <Typography className="blog-page-subtitle">{iv.value}</Typography>
                                                        ) : null }
                                                        { iv.type === 'P' ? (
                                                            <Typography className="blog-page-paragraph">{iv.value}</Typography>
                                                        ) : null }
                                                        { iv.type === 'I' ? (
                                                            <center><Image className="blog-page-image" src={iv.url} preview={false} /></center>
                                                        ) : null }
                                                        { iv.type === 'V' ? (
                                                            <center><ReactPlayer playing={true} url={iv.value} controls width={'100%'}/></center>
                                                        ) : null }
                                                    </Col>
                                                )) }
                                                </Row>
                                            </Col>
                                        )) }
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    ) }
                </Col>
            </Row>
        )
    }

}

export default TreinamentoPage;