import React from "react";
import { Card, Col, Image, Result, Row, Spin, Typography, message } from "antd";
import { POST_API, POST_CATCH, getToken } from "../services/api";
import ReactPlayer from "react-player";

class PageLoad extends React.Component {

    state = {
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        rows: []
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = async () => {
        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
        POST_API('/page_body/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({PAGE_ID: this.state._params[5]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({rows: res.data })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())
    }

    render = () => {
        return (
            <Row gutter={[8,8]}>
                <Col className="main-main padding-page" span={24}>
                    { this.state._screen ? <center><Spin /></center> : (
                        <>
                            { this.state.defaultValue?.ID > 0 ? (
                                <Row gutter={[16,16]}>
                                    <Col md={24} xs={24} style={{marginTop: 20}}>
                                        <Card size="small">
                                            { this.state.rows.map((v, i) => (
                                                <Row key={i} className="blog-page-row">
                                                    { v.columns && v.columns.map((cv, ci) => (
                                                        <Col key={ci} md={24/v.type} xs={24} className="blog-page-column">
                                                            <Row>
                                                            { cv.items.map((iv, ii) => (
                                                                <Col span={24} key={ii} style={{marginBottom: 10}}>
                                                                    { iv.type === 'T' ? (
                                                                        <Typography className="blog-page-subtitle">{iv.value}</Typography>
                                                                    ) : null }
                                                                    { iv.type === 'P' ? (
                                                                        <Typography className="blog-page-paragraph">{iv.value}</Typography>
                                                                    ) : null }
                                                                    { iv.type === 'I' ? (
                                                                        <center><Image className="blog-page-image" src={iv.url} preview={false} /></center>
                                                                    ) : null }
                                                                    { iv.type === 'V' ? (
                                                                        <center><ReactPlayer playing={true} url={iv.value} controls width={'100%'}/></center>
                                                                    ) : null }
                                                                </Col>
                                                            )) }
                                                            </Row>
                                                        </Col>
                                                    )) }
                                                </Row>
                                            ))}
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (
                                <Result title="Página não encontrada" />
                            ) }
                        </>
                    ) }
                </Col>
            </Row>
        )
    }

}

export default PageLoad;