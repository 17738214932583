import React from "react";
import { Card, Col, Image, Row, Typography, message } from "antd";
import { POST_API, POST_CATCH, getToken } from "../services/api";
import ReactPlayer from "react-player";


class PageLoad extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        defaultValue: null
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = async () => {
        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: 5}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    render = () => {
        return (
            <Row className="page" gutter={[8,8]}>
                <Col span={24}>
                    <Card size="small">
                        <Row gutter={[4,4]}>
                            { this.state.defaultValue?.CONTROL_PAGES && this.state.defaultValue?.CONTROL_PAGES.map((v, i) => (
                                <Col span={24} key={i}>
                                    { v.type === 'T' ? <Typography className="home-title"><b>{v.body}</b></Typography> : null }
                                    { v.type === 'P' ? <Typography className="text-justify">{v.body}</Typography> : null }
                                    { v.type === 'A' ? <> {v.arch === 'IMAGE' ? <Image className="showImage" src={v.body} preview={false} /> : <ReactPlayer playing={true} url={v.body} controls width={'100%'} /> } </> : null }
                                </Col>
                            )) }
                        </Row>
                    </Card>
                </Col>
            </Row>
        )
    }

}

export default PageLoad;