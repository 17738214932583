import React from "react";
import { Card, Col, Row, Spin, Typography } from "antd";
import { POST_API, getToken, getType } from "../services/api";
import Home from "./Home";
import * as echarts from 'echarts';


class Dashboard extends React.Component {

    state = { _loading: true, dados: null }

    componentDidMount = () => {
        setTimeout(() => {
            if (getType() === 'A' || getType() === 'RH') {
            POST_API('/credential/dashboard.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
    
                    // CARREGANDO COLABORADORES
                    var chartColab = window.document.getElementsByClassName('dataColaboradores');
                    var colabChart1 = echarts.init(chartColab[0]);
                    var colabChart2 = echarts.init(chartColab[1]);
                    var optionColab = {
                        tooltip: { trigger: 'item', axisPointer: { type: 'shadow' } }, dataZoom: [ { type: 'inside' } ],
                        grid: { left: '0px', right: '20px', bottom: '10px', top: '40px', containLabel: true },
                        legend: { center: 'center', type: 'scroll', orient: 'horizontal', itemWidth: 14, textStyle: { fontSize: 10 }, data: res.data.colab.graph.legend },
                        toolbox: { show: true, orient: 'vertical', left: 'right', top: 'center' },
                        xAxis: [ { type: 'category', axisTick: { show: false }, data: res.teste } ],
                        yAxis: [ { type: 'value' } ],
                        series: res.data.colab.graph.series
                    };
                    colabChart1.group = 'group1'
                    colabChart2.group = 'group1'
                    colabChart1.setOption(optionColab)
                    colabChart2.setOption(optionColab)

                    // CARREGANDO DOWNLOAD HOLERITE
                    var chartDnH = window.document.getElementsByClassName('dataDnH');
                    var dnHChart1 = echarts.init(chartDnH[0]);
                    var dnHChart2 = echarts.init(chartDnH[1]);
                    var optionDnH = {
                        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } }, dataZoom: [ { type: 'inside' } ],
                        grid: { left: '0px', right: '20px', bottom: '10px', top: '40px', containLabel: true },
                        legend: { center: 'center', type: 'scroll', orient: 'horizontal', itemWidth: 14, textStyle: { fontSize: 10 }, data: res.data.holer.graph.legend },
                        toolbox: { show: true, orient: 'vertical', left: 'right', top: 'center' },
                        xAxis: [ { type: 'category', axisTick: { show: false }, data: res.teste } ],
                        yAxis: [ { type: 'value' } ],
                        series: res.data.holer.graph.series
                    };
                    dnHChart1.group = 'group1'
                    dnHChart2.group = 'group1'
                    dnHChart1.setOption(optionDnH)
                    dnHChart2.setOption(optionDnH)

                    // CARREGANDO INTERAÇÕES
                    var chartIntA = window.document.getElementsByClassName('dataIntA');
                    var intChart1A = echarts.init(chartIntA[0]);
                    var intChart2A = echarts.init(chartIntA[1]);
                    var optionIntA = {
                        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } }, dataZoom: [ { type: 'inside' } ],
                        grid: { left: '0px', right: '20px', bottom: '10px', top: '40px', containLabel: true },
                        legend: { center: 'center', type: 'scroll', orient: 'horizontal', itemWidth: 14, textStyle: { fontSize: 10 }, data: res.data.inter.graph.legend },
                        toolbox: { show: true, orient: 'vertical', left: 'right', top: 'center' },
                        xAxis: [ { type: 'category', axisTick: { show: false }, data: res.teste } ],
                        yAxis: [ { type: 'value' } ],
                        series: res.data.inter.graph.series
                    };
                    intChart1A.group = 'group1'
                    intChart2A.group = 'group1'
                    intChart1A.setOption(optionIntA)
                    intChart2A.setOption(optionIntA)
                    
                    var chartPost = window.document.getElementsByClassName('dataPost');
                    var postChart1 = echarts.init(chartPost[0]);
                    var postChart2 = echarts.init(chartPost[1]);
                    var optionPost = {
                        tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } }, dataZoom: [ { type: 'inside' } ],
                        grid: { left: '0px', right: '25px', bottom: '10px', top: '40px', containLabel: true },
                        toolbox: { show: true, orient: 'vertical', left: 'right', top: 'center' },
                        xAxis: [ { type: 'category', axisTick: { show: false }, data: res.teste, boundaryGap: false } ],
                        yAxis: [ { type: 'value' } ],
                        series: res.data.post.graph.series
                    };
                    postChart1.setOption(optionPost)
                    postChart2.setOption(optionPost)

                    setInterval(() => {
                        colabChart1.resize()
                        colabChart2.resize()
                        dnHChart1.resize()
                        dnHChart2.resize()
                        intChart1A.resize()
                        intChart2A.resize()
                        postChart1.resize()
                        postChart2.resize()
                    }, 1000);

                }
            }).catch(() => null).finally(() => {
                this.setState({_loading: false})
            })
        }
        }, 500);
    }

    render = () => {

        if (getType() === 'C' || getType() === 'MK') {
            return <Home />
        }

        return (
            <Row className="page-content" gutter={[8,8]}> 
                <Col md={24} xs={24}>
                    <Card size="small" hoverable title={<Typography className="home-title">Colaboradores</Typography>}>
                        { this.state._loading ? <center><Spin style={{position: 'absolute', top: '50%', left: '50%'}} /></center> : null }
                        <div id="dataColaboradores" className="dataColaboradores" style={{width: '100%', height: 280}}> </div>
                    </Card>
                </Col>
                <Col md={8} xs={24}>
                    <Card size="small" hoverable title={<Typography className="home-title">Postagens</Typography>}>
                        { this.state._loading ? <center><Spin style={{position: 'absolute', top: '50%', left: '50%'}} /></center> : null }  
                        <div id="dataPost" className="dataPost" style={{width: '100%', height: 200}}> </div>
                    </Card>
                </Col>
                <Col md={8} xs={24}>
                    <Card size="small" hoverable title={<Typography className="home-title">Interações</Typography>}>
                        { this.state._loading ? <center><Spin style={{position: 'absolute', top: '50%', left: '50%'}} /></center> : null }  
                        <div id="dataIntA" className="dataIntA" style={{width: '100%', height: 200}}> </div>
                    </Card>
                </Col>
                <Col md={8} xs={24}>
                    <Card size="small" hoverable title={<Typography className="home-title">Download Holerite</Typography>}>
                        { this.state._loading ? <center><Spin style={{position: 'absolute', top: '50%', left: '50%'}} /></center> : null }
                        <div id="dataDnH" className="dataDnH" style={{width: '100%', height: 200}}> </div>
                    </Card>
                </Col>
            </Row>
        )

    }

}

export default Dashboard;