import React from "react";
import { Breadcrumb, Button, Col, Modal, Row, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoLockOpen, IoPencil, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken } from "../services/api";

// COMPONENTS
import Table from '../components/Table';

class Usuarios extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
                current: 1,
                total: 0,
                limit: 10
            },
        table_filter: null,
        table_sorter: null,
        table_select: [],
    }

    routes = [
        {title: <Link>Usuarios</Link>}
    ]

    componentDidMount = () => {
        this.onRenderColumns().then(() => this.onRenderData())
    }
    
    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/credential/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onRecover = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Restaurar senha padrão?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/credential/recpass.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => POST_CATCH()).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/credential/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        
        this.setState({
            table_column: [
                { title: 'Cod.', dataIndex: 'ID', key: 'credential.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Matrícula', dataIndex: 'COLABORADOR_ID', key: 'credential.COLABORADOR_ID', width: 150, sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Nome', dataIndex: 'NAME', key: 'credential.NAME', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Tipo', dataIndex: 'TYPE_NAME', key: 'credential.TYPE', width: 150, sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Setor', dataIndex: 'DES_DEPTO', key: 'credential.DES_DEPTO', width: 150, sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Link to={record.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={14} /></Button></Link></Col>
                        <Col><Button onClick={() => this.onAction(record.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={14} /></Button></Col>
                        <Col><Button onClick={() => this.onRecover(record.ID)} className="table__btn table__btn_view" title='Recuperar Senha' type="primary" shape="circle" size="small"><IoLockOpen size={14} /></Button></Col>
                    </Row>
                )},
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table_filter: filters,
            table_sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({
            table_pagination: {
                ...this.state.table_pagination,
                current: page,
                limit: size
            }
        })
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({
            table_search: e.target.value
        })
        this.onRenderData()
    }

    onSelect = (selectedRowKeys, selectedRows) => {
        this.setState({table_select: selectedRows})
    };

    onChangePage = () => {
        this.setState({table_data: []})
        this.onRenderData()
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Row className="page-content" align={'middle'}>
                <Row gutter={[8,8]} align={'middle'}>
                    <Col span={24}>
                        <Breadcrumb items={this.routes} />
                    </Col>
                    <Col span={24}>
                        <Table
                             _onRecover={this.onRecover}
                             _onAction={this.onAction}
                            _columns={this.state.table_column}
                            _data={this.state.table_data}
                            _pagination={this.state.table_pagination}
                            _loading={this.state.table_load}
                            _onChangeTable={this.onTableChange}
                            _onChangePagination={this.onPaginationChange}
                            _onChangeSearch={this.onSearchChange}
                            _search={this.state.table_search}
                            _rowSelection={{onChange: this.onSelect, getCheckboxProps: () => {}}}
                        />
                    </Col>
                </Row>
            </Row>
        )

    }

}

export default Usuarios;