import React from "react";
import { Breadcrumb, Button, Card, Col, Collapse, Modal, Row, Spin, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { IoPencil, IoReturnUpBackOutline, IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { POST_API, POST_CATCH, getToken, titleWeb } from "../../services/api";

// COMPONENTS
import TableComponent from "../../components/Table";

class FAQ extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table_load: true,
        table_column: [],
        table_data: [],
        table_search: '',
        table_pagination: {
            current: 1,
            total: 0,
            limit: 100
        },
        table_sorter: null,
        table_select: [],
        table_filter: {
            TYPE_VEHICLE_ID: '*'
        },
        filters: {
            TYPE_VEHICLE_ID: []
        }
    }

    back = "/dashboard/info";

    routes = [
        {title: <Typography className="page-bread active">FAQ</Typography>},
    ]

    componentDidMount = () => {
        titleWeb('FAQ')
        this.onRenderData()
    }

    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/faq/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination), filter: JSON.stringify(this.state.table_filter), sorter: JSON.stringify(this.state.table_sorter), search: this.state.table_search, type: this.props.type }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        table_data: res.data,
                        table_pagination: {
                            ...this.state.table_pagination,
                            total: res.summary.QTDE
                        }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    render = () => {
        
        if (this.state._navigate) {
            return (<Navigate to={'/painel'} />)
        }

        return (
            <Row className="page-content">
                <Col span={18}  style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                { this.props.type === 'list' ? (
                    <Col span={6}  style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'del-select', 'Excluir registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Deletar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={'add'}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Novo</Button></Link>
                    </Col>
                ) : (
                    <Col span={6}  style={{display: 'flex', justifyContent: 'flex-end'}}>
                        { this.state.table_select.length > 0 ? <Button onClick={() => this.onAction(null, 'rec-select', 'Recuperar registros?')} type="default" size="small" shape="round" className="sys-btn-default sys-btn-small"style={{marginRight: 5}}>Recuperar Selecionados ({this.state.table_select.length})</Button> : null }
                        <Link to={this.back}><Button onClick={this.onChangePage} type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                    </Col>
                ) }
                <Col span={24}>
                    { this.state.table_load ? <center><Spin /></center> :
                        <Card size="small">
                            <Collapse accordion>
                                { this.state.table_data.map((v,i) => (
                                    <Collapse.Panel header={v.ASK}>
                                        <p>{v.ASWER}</p>
                                    </Collapse.Panel>
                                )) }
                            </Collapse>
                        </Card>
                    }
                </Col>
            </Row>
        )

    }

}

export default FAQ;