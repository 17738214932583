import React from "react";
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Select, Spin, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb } from "../../services/api";

class ConfPageForm extends React.Component {

    state = {
        _navigate: false,
        _loading: false,
        _screen: true,
        _params: window.location.href.split('/'),
        defaultValue: null,
        doc: false,
        type: null,
        filters: { TYPE_VEHICLE_ID: [] },
        page: [],
        setor: []
    }

    back = this.props.page === 'P' ? "/dashboard/info/confpag" : "/dashboard/treinamento/conf";

    routes = [
        {title: <Link to={this.back}><Typography className="page-bread">{ this.props.page === 'P' ? 'Página' : 'Treinamento' }</Typography></Link>},
        {title: <Typography className="page-bread active">{this.props.type === 'add' ? 'Adicionar' : 'Editar'}</Typography>},
    ]

    componentDidMount = () => {
        titleWeb('FAQ')
        
        POST_API('/setor/search.php', { token: getToken(), type: this.props.type }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ setor: res.data })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH())

        if (this.props.type === 'add') {
            this.setState({_screen: false})
        } else if (this.props.type === 'edit') {
            this.onView()
        }
    }

    onAdd = ( type ) => {
        this.setState({ page: [ ...this.state.page, { type: type, load: 'new' } ] })
    }

    onDel = ( index ) => {
        this.setState({_screen: true})
        var pageList = this.state.page
        pageList.splice(index, 1)
        setTimeout(() => {
            this.setState({ page: pageList, _screen: false })
        }, 500);
    } 

    onUp = ( index ) => {
        this.setState({_screen: true})
        var pageList = this.onChangeArray(index, index-1)
        setTimeout(() => {
            this.setState({page: pageList, _screen: false})
        }, 500);
    }

    onDown = ( index ) => {
        this.setState({_screen: true})
        var pageList = this.onChangeArray(index, index+1)
        setTimeout(() => {
            this.setState({page: pageList, _screen: false})
        }, 500);
    }

    onChangeArray = ( old_index, new_index ) => {
        var pages = this.state.page
        if (new_index >= pages.length) {
            var k = new_index - pages.length;
            while ((k--) + 1) {
                pages.push(undefined);
            }
        }
        pages.splice(new_index, 0, pages.splice(old_index, 1)[0]);
        return pages
    };

    onView = async () => {
        POST_API('/page/search.php', { token: getToken(), type: 'view', filter: JSON.stringify({ID: this.state._params[6]}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, page: res.data.CONTROL_PAGES})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        values.TYPE = this.props.page
        POST_API('/page/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate) {
            return (<Navigate to={this.back} />)
        }

        return (
            <Row className="page-content">
                <Col span={18} style={{marginBottom: '18px'}}>
                    <Breadcrumb items={this.routes} />
                </Col>
                <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to={this.back}><Button type="primary" size="small" shape="round" className="sys-btn-primary sys-btn-small">Voltar</Button></Link>
                </Col>
                { this.state._screen ? <Col span={24}><center><Spin /></center></Col> : (
                    <Col span={24}>
                        <Card>
                            <Form layout="vertical" className={`admin-form`} onFinish={this.onSend}>
                                <Form.Item initialValue={this.state.defaultValue?.ID} hidden name="ID">
                                    <Input />
                                </Form.Item>
                                <Row gutter={[16,0]}>
                                    <Col md={ this.props.page === 'P' ? 24 : 20 } xs={24}>
                                        <Form.Item initialValue={this.state.defaultValue?.TITLE} label="Nome da Página" name="TITLE" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={ this.props.page === 'P' ? 0 : 4 } xs={24}>
                                        <Form.Item initialValue={this.state.defaultValue?.SETOR_ID} label="Setor" name="SETOR_ID" class="profile-form-item" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                            <Select>
                                                <Select.Option value={'0'}>Todos</Select.Option>
                                                { this.state.setor.map((v, i) => (
                                                    <Select.Option value={v.ID}>{v.NAME}</Select.Option>
                                                )) }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" style={{float: 'right'}}>Salvar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                ) }
            </Row>
        )

    }

}

export default ConfPageForm;