import React from "react";
import { Button, Card, Col, Divider, Form, Image, Input, Modal, Row, Spin, Typography, Upload, message } from "antd";
import { POST_API, POST_CATCH, getToken, getType, getUPLOADAPI } from "../services/api";
import { Navigate } from "react-router-dom";
import { IoTrash } from "react-icons/io5";
import { ExclamationCircleOutlined } from '@ant-design/icons';


class TimelineEdit extends React.Component {

    state = {
        data: [ ],
        text: '',
        file: [],
        navigate: false,
        params: window.location.href.split('/'),
        loading: false,
        table_load: true,
        table_pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        loadmore: false,
        nomore: false,
    }

    componentDidMount = () => {
        POST_API('/timeline/search.php', { token: getToken(), filter: JSON.stringify({ ID: this.state.params[5] }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ data: res.data[0] })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
    }

    onSend = (values) => {
        this.setState({loading: true})
        POST_API('/timeline/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                this.setState({navigate: true})
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() }).finally(() => {
            this.setState({loading: false})
        })
        
    }

    onDelete = (ID, type, title) => {

        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/timeline/delpic.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.setState({table_load: true})
                        POST_API('/timeline/search.php', { token: getToken(), filter: JSON.stringify({ ID: self.state.params[5] }) }).then(rs => rs.json()).then(res => {
                            if (res.return) {
                                self.setState({ data: res.data[0] })
                            } else {
                                message.error({ content: res.msg, key: 'screen' })
                            }
                        }).catch(() => POST_CATCH()).finally(() => self.setState({table_load: false }))
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    render = () => {

        if (this.state.navigate) return <Navigate to={'/dashboard/timeline'} />

        return (
            <Row className="page" gutter={[8,8]} ref={(ref) => this.scrollHere = ref}>
                { getType() === 'A' || getType() === 'RH' || getType() === 'MK' ? (

                <Col span={24}>
                    { this.state.table_load ? <center><Spin /></center> : 
                        <Card size='small' title="Atualizar Publicação">
                            {this.state.loading ? <center><Spin /></center> : (

                                <Form layout="vertical" onFinish={this.onSend} initialValues={this.state.data}>
                                    <Form.Item name="ID" hidden>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="BODY">
                                        <Input placeholder="Escrever mensagem" />
                                    </Form.Item>
                                    <Form.Item name="PHOTOS">
                                        <Upload
                                            accept="image/*,video/*"
                                            action={getUPLOADAPI}
                                        >
                                            <div>
                                                <Button block type="primary">Adicionar Mídia</Button>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" block type="default">Atualizar</Button>
                                    </Form.Item>
                                    <Divider>Anexos</Divider>
                                    <Row gutter={[4,4]}>
                                        { this.state.data.PHOTOS.map((v, i) => (
                                            <Col span={24} key={i}><Image src={v.BODY} /><Button onClick={() => this.onDelete(v.ID, 'del', 'Excluir foto?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="middle" style={{position: 'absolute', top: 5, right:8}}><IoTrash size={22} /></Button></Col>
                                        )) }
                                    </Row>
                                </Form>
                            )}
                        </Card>
                    }
                </Col>
                ) : null }
            </Row>
        )
    }

}

export default TimelineEdit;