import React from "react";
import { Button, Card, Col, Form, Image, Input, Popover, Row, Spin, Typography, Upload, message } from "antd";
import TimelineComponent from "../components/Timeline";
import { POST_API, POST_CATCH, getToken } from "../services/api";
import ReactPlayer from "react-player";
import { IoBulb, IoBulbOutline, IoHappyOutline, IoHappy, IoHeart, IoHeartOutline, IoPencil, IoThumbsUp, IoThumbsUpOutline, IoTrash } from 'react-icons/io5';
import { BiParty, BiSolidParty  } from "react-icons/bi";
import { FaRegHandshake, FaHandshakeSimple  } from "react-icons/fa6";

class Timeline extends React.Component {

    state = {
        data: [ ],
        text: '',
        file: [],
        select: null,
        loading: false,
        table_load: true,
        table_pagination: {
            current: 1,
            page: 10
        },
        loadmore: false,
        nomore: false,
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    newPagination = async () => {
        this.setState({
            table_pagination: {
                current: this.state.table_pagination.current+1,
                page: 10
            },
        })
    }

    loadMore = () => {
        this.setState({loadmore: true})
        this.newPagination().then(() => this.onRenderDataLoadMore())
    }

    onRenderDataLoadMore = () => {
        POST_API('/timeline/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                if (res.data.length > 0) {
                    this.setState({ data: [ ...this.state.data, ...res.data] })
                } else {
                    this.setState({ nomore: true })
                }
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({loadmore: false}))
    }

    onRenderDataLoad = () => {
        setTimeout(async () => {
            POST_API('/timeline/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ data: res.data, })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);
    }

    // LOAD DATA
    onRenderData = () => {
        this.setState({table_load: true })
            POST_API('/timeline/search.php', { token: getToken(), pagination: JSON.stringify(this.state.table_pagination) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ data: res.data })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
    }

    onAction = (item, type) => {
        POST_API('/timeline/like.php', { token: getToken(),  TYPELIKE: type, ID: item.ID, TYPE: (item[`SELF_${type}`] === '0') ? 'LIKE' : 'UNLIKE' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.onRenderDataLoad()
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() })
    }

    onSelect = (i) => {
        this.setState({select: this.state.select === i ? null : i})
    }

    onClick = ( item, type ) => {
        this.onAction(item, type)
    }

    render = () => {
        return (
            <Row gutter={[16,16]} justify={'center'} className="page">
                <Col md={12} xs={24}>
                    <Row gutter={[16,16]}>
                        { this.state.table_load ? <Col span={24}><center><Spin /></center></Col> : this.state.data.map((v, i) => v.MEDIA_TYPE === 'VIDEO' ? null : (
                            <Col span={24}>
                                <div className="card-timeline">
                                    { v.MEDIA_TYPE === 'IMAGE' || v.MEDIA_TYPE === 'CAROUSEL_ALBUM' ?
                                        <Image className="card-timeline-image" preview={false} src={v.MEDIA_URL} />
                                    : <ReactPlayer className="card-timeline-image" url={v.MEDIA_URL} controls width={'100%'} /> }
                                    <Row align={'middle'} justify={'space-around'} style={{marginTop: 8}}>
                                        <Col><center><Popover trigger="hover" content="Gostei"><Button onClick={() => this.onClick(v, 'GOSTEI')} type="link" className="btn-link"> {v.SELF_GOSTEI === '0' ? <IoThumbsUpOutline size={20}/> : <IoThumbsUp color="#378fe9" size={20}/>}</Button>{v.GOSTEI}</Popover></center></Col>
                                        <Col><center><Popover trigger="hover" content="Parabéns"><Button onClick={() => this.onClick(v, 'PARABENS')} type="link" className="btn-link"> {v.SELF_PARABENS === '0' ? <BiParty size={20}/> : <BiSolidParty color="#5f9b41" size={20}/>}</Button>{v.PARABENS}</Popover></center></Col>
                                        <Col><center><Popover trigger="hover" content="Apoio"><Button onClick={() => this.onClick(v, 'APOIO')} type="link" className="btn-link"> {v.SELF_APOIO === '0' ? <FaRegHandshake size={20}/> : <FaHandshakeSimple color="#a871ea" size={20}/>}</Button>{v.APOIO}</Popover></center></Col>
                                        <Col><center><Popover trigger="hover" content="Amei"><Button onClick={() => this.onClick(v, 'AMEI')} type="link" className="btn-link"> {v.SELF_AMEI === '0' ? <IoHeartOutline size={20}/> : <IoHeart color="#cb112d" size={20}/>}</Button>{v.AMEI}</Popover></center></Col>
                                        <Col><center><Popover trigger="hover" content="Genial"><Button onClick={() => this.onClick(v, 'GENIAL')} type="link" className="btn-link"> {v.SELF_GENIAL === '0' ? <IoBulbOutline size={20}/> : <IoBulb color="#e9a53f" size={20}/>}</Button>{v.GENIAL}</Popover></center></Col>
                                        <Col><center><Popover trigger="hover" content="Divertido"><Button onClick={() => this.onClick(v, 'DIVERTIDO')} type="link" className="btn-link"> {v.SELF_DIVERTIDO === '0' ? <IoHappyOutline size={20}/> : <IoHappy color="#62a6e9" size={20}/>}</Button>{v.DIVERTIDO}</Popover></center></Col>
                                    </Row>
                                    <Typography className="card-timeline-text" style={{ textWrap: this.state.select === i ? '' : 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{v.CAPTION}</Typography>
                                    <Typography className="card-timeline-more" onClick={() => this.onSelect(i)}>Ver {this.state.select === i ? 'menos' : 'mais'}</Typography>
                                </div>
                            </Col>
                        )) }
                        <Col span={24}>
                            { this.state.loadmore ? <center><Spin /></center> : ( this.state.nomore ? <center><Typography>Não há mais publicações</Typography></center> : <Button onClick={this.loadMore} block type="primary">Carregar mais antigo</Button> ) }
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

}

export default Timeline;