import React from "react";
import { Avatar, Button, Card, Carousel, Image, List, Modal, Popover, Typography, message } from 'antd';

import { IoBulb, IoBulbOutline, IoHappyOutline, IoHappy, IoHeart, IoHeartOutline, IoPencil, IoThumbsUp, IoThumbsUpOutline, IoTrash } from 'react-icons/io5';
import { BiParty, BiSolidParty  } from "react-icons/bi";
import { FaRegHandshake, FaHandshakeSimple  } from "react-icons/fa6";


import ReactPlayer from "react-player";
import { POST_API, POST_CATCH, getToken, getType } from "../../services/api";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import logo from '../../assets/slog1.png';

class TimelineComponent extends React.Component {

    onDelete = (ID, type, title) => {

        const { onRender } = this.props

        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/timeline/del.php', { token: getToken(), ID: ID, type: type }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        onRender()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onClick = ( type ) => {
        const { item, onAction } = this.props 
        onAction(item, type)
    }

    render = () => {

        const { item, index } = this.props

        return (
            <Card size="small" key={index}
                title={
                    <List>
                        <List.Item 
                            actions={ getType() === 'A' || getType() === 'MK' ? [
                                <Link to={item.ID}><Button className="table__btn table__btn_edit" title='Editar' type="primary" shape="circle" size="small"><IoPencil size={16} /></Button></Link>,
                                <Button onClick={() => this.onDelete(item.ID, 'del', 'Excluir registro?')} className="table__btn table__btn_delete" title='Excluir' type="primary" shape="circle" size="small"><IoTrash size={16} /></Button>,
                            ] : null}
                        >
                            <List.Item.Meta
                                avatar={<Avatar size={30} src={item.CREDENTIAL_AVATAR} />}
                                title={<Typography style={{marginTop: 3, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>Comunicação</Typography>}
                            />
                        </List.Item>
                    </List>
                }
                cover={
                    <>
                        { item.PHOTOS.length > 0 ? (
                            <Carousel className="timelineA-carousel">
                                { item.PHOTOS.map((v,i) => (
                                    <div className="timeline-carousel" key={i}>
                                        { v.OPTION === 'IMAGE' ? <Image src={v.BODY} preview={false} /> : <ReactPlayer url={v.BODY} controls width={'100%'} />}
                                    </div>
                                )) }
                            </Carousel>
                        ) : null }
                    </>
                }
                actions={[
                    <center><Popover trigger="hover" content="Gostei"><Button onClick={() => this.onClick('GOSTEI')} type="link" className="btn-link"> {item.SELF_GOSTEI === '0' ? <IoThumbsUpOutline size={20}/> : <IoThumbsUp color="#378fe9" size={20}/>}</Button>{item.GOSTEI}</Popover></center>,
                    <center><Popover trigger="hover" content="Parabéns"><Button onClick={() => this.onClick('PARABENS')} type="link" className="btn-link"> {item.SELF_PARABENS === '0' ? <BiParty size={20}/> : <BiSolidParty color="#5f9b41" size={20}/>}</Button>{item.PARABENS}</Popover></center>,
                    <center><Popover trigger="hover" content="Apoio"><Button onClick={() => this.onClick('APOIO')} type="link" className="btn-link"> {item.SELF_APOIO === '0' ? <FaRegHandshake size={20}/> : <FaHandshakeSimple color="#a871ea" size={20}/>}</Button>{item.APOIO}</Popover></center>,
                    <center><Popover trigger="hover" content="Amei"><Button onClick={() => this.onClick('AMEI')} type="link" className="btn-link"> {item.SELF_AMEI === '0' ? <IoHeartOutline size={20}/> : <IoHeart color="#cb112d" size={20}/>}</Button>{item.AMEI}</Popover></center>,
                    <center><Popover trigger="hover" content="Genial"><Button onClick={() => this.onClick('GENIAL')} type="link" className="btn-link"> {item.SELF_GENIAL === '0' ? <IoBulbOutline size={20}/> : <IoBulb color="#e9a53f" size={20}/>}</Button>{item.GENIAL}</Popover></center>,
                    <center><Popover trigger="hover" content="Divertido"><Button onClick={() => this.onClick('DIVERTIDO')} type="link" className="btn-link"> {item.SELF_DIVERTIDO === '0' ? <IoHappyOutline size={20}/> : <IoHappy color="#62a6e9" size={20}/>}</Button>{item.DIVERTIDO}</Popover></center>,
                ]}
            >  
                <Typography className="timeline-title">{item.NAME}</Typography>
                <Typography className="timeline-text">{item.BODY}</Typography>
                <Typography className="timeline-date">Publicado em {item.DATETIME_FORMAT_INSERT}</Typography>
            </Card>
        )
    }

}

export default TimelineComponent;