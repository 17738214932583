import React from "react";
import { Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import ReactInputMask from "react-input-mask";
import { POST_API, POST_CATCH, getToken } from "../services/api";


class Cadastro extends React.Component {

    state = { _user: null, _loading: true }

    componentDidMount = () => {
        this.onView()
    }

    onView = () => {
        this.setState({_loading: true})
        if (getToken()) {
            POST_API('/credential/dados.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_user: res.user})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        }
    }

    render = () => {
        return (
            <Row className="page" gutter={[16, 16]}>
                <Col span={24} className="data-profile">
                    <Card bordered title="Meus Dados" size="small">
                        { this.state._loading ? <center><Spin /></center> : (
                            <Form layout="vertical">
                                <Row gutter={[10,10]}>
                                    <Col md={18} xs={24}>
                                        <Typography>Nome</Typography>
                                        <Input disabled value={this.state._user?.RH?.NOM_COLAB} />
                                    </Col>
                                    <Col md={6} xs={16}>
                                        <Typography>CPF</Typography>
                                        <ReactInputMask value={this.state._user?.RH?.CPF} disabled mask={'999.999.999-99'} className="ant-input css-dev-only-do-not-override-byeoj0 css-byeoj0" />
                                    </Col>
                                    <Col md={4} xs={8}>
                                        <Typography>CEP</Typography>
                                        <ReactInputMask value={this.state._user?.RH?.CEP_COLAB} disabled  onBlur={this.buscaCEP} mask={'99999-999'} className="ant-input css-dev-only-do-not-override-byeoj0 css-byeoj0" />
                                    </Col>
                                    <Col md={16} xs={24}>
                                        <Typography>Logradouro</Typography>
                                        <Input disabled value={this.state._user?.RH?.END_COLAB ? this.state._user?.RH?.END_COLAB : ''} />
                                    </Col>
                                    <Col md={4} xs={8}>
                                        <Typography>Número</Typography>
                                        <Input disabled value={this.state._user?.RH?.NUM_END_COLAB} />
                                    </Col>
                                    <Col md={12} xs={16}>
                                        <Typography>Bairro</Typography>
                                        <Input disabled value={this.state._user?.RH?.BAIRRO_COLAB} />
                                    </Col>
                                    <Col md={12} xs={18}>
                                        <Typography>Cidade</Typography>
                                        <Input disabled value={this.state._user?.RH?.DES_MUNICIPIO_COLAB} />
                                    </Col>
                                    {/* <Col md={3} xs={6}>
                                        <Typography>UF</Typography>
                                        <Input disabled value={'SP'} />
                                    </Col> */}
                                    <Col md={24} xs={24}>
                                        <Typography>E-mail</Typography>
                                        <Input disabled value={this.state._user?.LOGIN} />
                                    </Col>
                                    {/* <Col md={6} xs={12}>
                                        <Form.Item name="PHONE_1" label="1º Telefone" class="profile-form-item">
                                            <ReactInputMask  mask={'+55 99 99999-9999'} className="ant-input css-dev-only-do-not-override-byeoj0 css-byeoj0" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        <Form.Item name="PHONE_2" label="2º Telefone" class="profile-form-item">
                                            <ReactInputMask  mask={'+55 99 99999-9999'} className="ant-input css-dev-only-do-not-override-byeoj0 css-byeoj0" />
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                            </Form>
                        ) }
                    </Card>
                </Col>
            </Row>
        )
    }

}

export default Cadastro;