import React from "react";
import { Button, Card, Checkbox, Col, Divider, Form, Input, Row, Spin, message } from "antd";
import { POST_API, POST_CATCH, getToken } from "../../services/api";

import './styles.css';
import { Navigate } from "react-router-dom";

class TreinamentoForm extends React.Component {

    state = {
        _loading: true,
        _screen: true,
        tabs: [],
        checkedList: [],
        indeterminate: false,
        checkAll: false,
        _navigate: false,
        defaultValue: null,
        _params: window.location.href.split('/'),
    }

    componentDidMount = () => {
        this.onLoadTab()
        if (this.props.type === 'edit') {
            this.onView()
        } else {
            this.setState({_screen: false})
        }
    }

    onView = async () => {
        var filter = { ID: this.state._params[5] }
        POST_API('/training/search.php', { token: getToken(), type: 'view', filter: JSON.stringify(filter) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({defaultValue: res.data, checkedList: res.data.DEPTO})
                setTimeout(() => {
                    this.onCheckVerify()
                }, 500);
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_screen: false}))
    }

    onLoadTab = () => {
        POST_API('/credential/depto.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ tabs: res.check })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false }))
    }

    onChange = (list) => {
        this.setState({ checkedList: list });
        setTimeout(() => {
            this.onCheckVerify()
        }, 500);
    };

    onCheckAllChange = (e) => {
        this.setState({ checkedList: e.target.checked ? this.state.tabs : [] })
        setTimeout(() => {
            this.onCheckVerify()
        }, 500);
    };

    onCheckVerify = () => {
        this.setState({
            checkAll: this.state.tabs.length === this.state.checkedList.length,
            indeterminate: this.state.checkedList.length > 0 && this.state.checkedList.length < this.state.tabs.length
        })
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/training/save.php', { token: getToken(), type: this.props.type, master: JSON.stringify(values), checks: this.state.checkedList }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        if (this.state._navigate)  return (<Navigate to={'..'} />)
        if (this.state._screen)  return (<Row className="page-content" gutter={[16, 16]}><Col span={24}><center><Spin /></center></Col></Row>)

        return (
            <Row className="page-content" gutter={[16, 16]}>
                <Col span={24}>
                    <Card size="small">
                        <Form layout="vertical" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input />
                            </Form.Item>
                            <Row gutter={[8,8]}>
                                <Col xs={24} md={20}>
                                    <Form.Item name='TITLE' label="Nome do Treinamento" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4}>
                                    <Form.Item name='CREATE_BY' label="Criado por" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Departamentos" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                        <Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckAllChange} checked={this.state.checkAll}>Selecionar Todos</Checkbox>
                                        <Divider style={{marginBottom: 4, marginTop: 4}} />
                                        <Checkbox.Group options={this.state.tabs} value={this.state.checkedList} onChange={this.onChange} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="primary" shape="round" style={{float: 'right'}}>Salvar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        )
        
    }

}

export default TreinamentoForm;