import { Button, Card, Col, Divider, Image, Row, Typography, message } from "antd";
import React from "react";

import bigbag from '../../assets/bigbag.png';

import './style.css';
import { POST_API, POST_CATCH, getToken } from "../../services/api";

class FAQButton extends React.Component {

    state = { 
        click: false,
        data: [],
        select: null
    }

    componentDidMount = () => {
        this.onRenderData()
    } 

    onRenderData = () => {
        this.setState({table_load: true })
        setTimeout(async () => {
            POST_API('/faq/search.php', { token: getToken(), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        data: res.data,
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({table_load: false }))
        }, 500);
    }

    onClick = (v) => {

        setTimeout(async () => {
            POST_API('/faq/click.php', { token: getToken(), master: JSON.stringify({ FAQ_ID: v.ID }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ select: v })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH())
        }, 500);

    }

    render = () => {

        const { user } = this.props

        return (
            <div className="faq-content">
                <Card className={this.state.click ? "faq-card active" : "faq-card"}>
                    <Typography className="faq-title">Olá {user?.NAME}!</Typography>
                    <Typography className="faq-title">Em que posso te ajudar?</Typography>
                    <Divider />
                    { this.state.select ? (
                        <Row gutter={[8,8]} className="faq-row">
                            <Col span={24}><Typography>{this.state.select.ASWER}</Typography></Col>
                            <Col span={24}><Button onClick={() => this.setState({select: null})} type="primary" block shape="round">Voltar</Button></Col>
                        </Row>
                    ) : <Row gutter={[8,8]} className="faq-row">
                            {  this.state.data && this.state.data.map((v, i) => (
                                <Col span={24}><div type="default" onClick={() => this.onClick(v)} className="faq-button" block><Typography>{v.ASK}</Typography></div></Col>
                            )) }
                    </Row> }
                </Card>
                <Image onClick={() => this.setState({click: !this.state.click})} className={this.state.click ? "faq-img active" : "faq-img"} src={bigbag} preview={false} />
            </div>
        )
    }

}

export default FAQButton;