import React from "react";
import { Layout, Card, Image, Typography, Form, Input, Button, message, InputNumber } from "antd";
import { Link, Navigate } from 'react-router-dom';

import logo from '../assets/slog1.png';
import ReactInputMask from "react-input-mask";
import { POST_API, POST_CATCH } from "../services/api";

class First extends React.Component {

    state = { _navigate: false, valor: '000000000000000' }

    onSend = (values) => {

        this.setState({_loading: true})
        POST_API('/credential/first_access.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({_loading: false}))

    }

    render = () => {

        if (this.state._navigate) return ( <Navigate to='/' />)

        return (
            <Layout.Content className="login-content">
                <Card className="login-card">
                    <center><Image src={logo} preview={false} className="login-card-image"/></center>
                    <Typography className="login-card-title">Primeiro acesso</Typography>
                    <Form layout="vertical" onFinish={this.onSend}>
                        <Form.Item label="CPF" name="CPF" className="login-form-item" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                            <ReactInputMask mask={'999.999.999-99'} size="large" placeholder="CPF" className="ant-input ant-input-lg css-dev-only-do-not-override-byeoj0 css-byeoj0" />
                        </Form.Item>
                        <Form.Item label="Matrícula" name="MATRICULA" className="login-form-item" rules={[{required: true, message: 'Campo Obrigatório'}]}>
                            <InputNumber size="large" placeholder="Número da Matrícula" maxLength={15} style={{width: '100%'}} />
                        </Form.Item>
                        <Form.Item label="E-mail" name="EMAIL" className="login-form-item" rules={[{required: true, message: 'Campo Obrigatório'}, {type: 'email', message: 'E-mail inválido'}]}>
                            <Input size="large" placeholder="Endereço de e-mail" />
                        </Form.Item>
                        <Form.Item className="login-form-item">
                            <Button htmlType="submit" type="primary" block className="btn-primary" shape="round" loading={this.state._loading}>Realizar o primeiro acesso</Button>
                            <Link to="/"><Button type="link" block className="btn-link" shape="round">Voltar</Button></Link>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout.Content>
        )
    }

}

export default First;