import React from "react";
import { Button, Card, Checkbox, Col, Dropdown, Modal, Popover, Row, Skeleton, Spin, Typography, message } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken, getType } from "../../services/api";

import { IoArrowBack, IoEllipsisVertical } from "react-icons/io5";
import TreinamentoPage from "./Page";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import TreinamentoQuestion from "./Question";
import { BsQuestionCircle } from "react-icons/bs";
import { RiPagesLine } from "react-icons/ri";

class TreinamentoClass extends React.Component {

    state = {
        loadCurso: true,
        curso: null,
        loadData: true,
        data: [],
        select: null,
        screen: true,
        rows: [],
        verify: 0,
        checked: 0,
        _params: window.location.href.split('/'),
        title: 'SELECIONE UMA AULA OU QUESTIONÁRIO AO LADO'
    }

    componentDidMount = () => {
        this.onRenderCurso()
        this.onRenderData()
    }

    onRenderCurso = () => {
        this.setState({loadCurso: true })
        setTimeout(async () => {
            POST_API('/training/search.php', { token: getToken(), filter: JSON.stringify({ ID: this.state._params[5] }), type: 'self' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ curso: res.data })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({loadCurso: false }))
        }, 500);
    }

    onRenderData = () => {
        this.setState({loadData: true })
        setTimeout(async () => {
            POST_API('/training_class/search.php', { token: getToken(), filter: JSON.stringify({ TRAINING_ID: this.state._params[5] }), type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({ data: res.data, checked: res.checked })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({loadData: false }))
        }, 500);
    }

    onChangeTab = (key) => {
        this.setState({key: key, loadData: true})
    }

    onSelect = (item) => {
        this.setState({select: item, screen: true, title: item.TITLE})
        if (item.TYPE_CLASS === 'P') {
            POST_API('/training_page/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({TRAINING_CLASS_ID: item.ID}), sorter: JSON.stringify({ columnKey: 'training_page.`ROW`, training_page.`COLUMN`, training_page.`ITEM`', order: 'ascend' }) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({rows: res.data})
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({screen: false}))
        } else {
            POST_API('/training_question/search.php', { token: getToken(), type: 'list', filter: JSON.stringify({TRAINING_CLASS_ID: item.ID}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({rows: res.data, verify: res.verify})
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({screen: false}))
        }
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/training_class/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onChecked = (e, v) => {
        POST_API('/training_class/checked.php', { token: getToken(), ID: v.ID, value: e.target.checked }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.onRenderData()
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {POST_CATCH()}).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        return (
            <Row className="page-content" gutter={[16, 16]}>
                <Col span={24}>
                    <Card size="small">
                        <Row justify={'space-between'}>
                            <Col>
                                <Row gutter={[8,8]} align={'bottom'}>
                                    <Col><Popover content="Voltar"><Link to={'/dashboard/treinamento'}><IoArrowBack size={20} className="tr-button"/></Link></Popover></Col>
                                    <Col>{ this.state.loadCurso ? <Skeleton.Input /> : <Typography className="tr-title">{this.state.curso?.TITLE}</Typography> }</Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row gutter={[4,4]} justify={'end'}>
                                    { getType() === 'A' || getType() === 'MK' ? <Col><Link to={'aula/add'}><Button type="primary" shape="round">Adicionar Aula</Button></Link></Col> : null }
                                    { getType() === 'A' || getType() === 'MK' ? <Col><Link to={'quest/add'}><Button type="primary" shape="round">Adicionar Questionário</Button></Link></Col> : null }
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Row className="tr-row-class" style={{flexWrap: 'nowrap'}} gutter={[8,8]}>
                        <Col flex={'auto'} className="tr-class">
                            <Card size="small" className="tr-card-training">
                                <Typography style={{marginBottom: 10, fontWeight: 600, textTransform: 'uppercase', borderBottom: '1px solid var(--primary)'}}>{this.state.title}</Typography>
                                <div className="tr-scroll">
                                    { this.state.select?.TYPE_CLASS === 'P' ? (
                                        <TreinamentoPage rows={this.state.rows} screen={this.state.screen} />
                                    ) : null }
                                    { this.state.select?.TYPE_CLASS === 'Q' ? (
                                        <TreinamentoQuestion rows={this.state.rows} screen={this.state.screen} verify={this.state.verify} />
                                    ) : null }
                                </div>
                            </Card>
                        </Col>
                        <Col flex={'300px'} className="tr-conteudo">
                            <Card size="small" className="tr-card-training">
                                <Typography style={{marginBottom: 10, fontWeight: 600, textTransform: 'uppercase', borderBottom: '1px solid var(--primary)'}}>Conteúdo do curso ({this.state.checked}/{this.state.data.length})</Typography>
                                <div className="tr-scroll">
                                    { this.state.loadData ? <Row gutter={[8,8]} justify={'center'} align={'middle'} style={{height: '100%'}}><Col><Spin /></Col></Row> : (
                                        <Row gutter={[8,0]} align={'top'}>
                                            { this.state.data.length === 0 ? <Col span={24}><Typography>Nenhum conteúdo encontrado</Typography></Col> : this.state.data.map((v, i) => (
                                                <Col span={24} key={i} className={ this.state.select === v ? "tr-list-class active" : "tr-list-class"}>
                                                    <Row gutter={[8,8]} style={{padding: 6, flexWrap: 'nowrap'}} >
                                                        <Col><Checkbox checked={v.checked} onChange={(e) => this.onChecked(e, v)} /></Col>
                                                        <Col><Typography onClick={() => this.onSelect(v)} className="tr-list-link">{ v?.TYPE_CLASS === 'P' ? <RiPagesLine style={{marginRight: 8}} /> : <BsQuestionCircle style={{marginRight: 8}} /> }{i+1}. {v.TITLE}</Typography></Col>
                                                        { getType() === 'A' || getType() === 'MK' ? 
                                                            <Dropdown menu={{ items: [ {label: <Link to={ (v?.TYPE_CLASS === 'P' ? 'aula/' : 'quest/') + v.ID+'/edit'}>Editar</Link>, key: '1'}, {label: 'Deletar', key: '2', onClick: () => this.onAction(v.ID, 'del', 'Excluir registro?')} ] }} placement="bottomCenter">
                                                                <IoEllipsisVertical className="tr-card-op" />
                                                            </Dropdown>
                                                        : null }
                                                    </Row>
                                                </Col>
                                            )) }
                                        </Row>
                                    )  }
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                {/* FUTURAMENTE <Col span={24}>
                    <Card size="small">
                        <Tabs className="tr-tab" items={[
                            { label: 'VISÃO GERAL', key: '2' },
                            { label: 'PERGUNTAS E RESPOSTAS', key: '3' },
                            { label: 'AVALIAÇÕES', key: '4' }
                        ]} onChange={this.onChangeTab} />
                    </Card>
                </Col> */}
            </Row>
        )
        
    }

}

export default TreinamentoClass;