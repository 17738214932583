import React from "react";
import { Avatar, Button, Card, Col, Divider, Form, Input, Row, Spin, Tag, Typography, Upload, message } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken, getType, getUPLOADAPI } from "../services/api";
import { IoCameraOutline } from "react-icons/io5";

class Perfil extends React.Component {

    state = { _user: null, _loading: true }

    componentDidMount = () => {
        this.onView()
    }

    onChangePic = (value) => {
        if (value.file.response?.url) {
            this.setState({_screen: true})
            POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({AVATAR: value.file.response?.url}) }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    message.success({ content: res.msg, key: 'screen' })
                } else {
                    message.warning({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.onView())
        }
    }

    onChangeName = (value) => {
        POST_API('/credential/self-save.php', { token: getToken(), master: JSON.stringify({NAME: value}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView())
    }

    onView = () => {
        this.setState({_loading: true})
        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_user: res.user})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        }
    }

    onSend = (values) => {
        this.setState({_screen: true})
        POST_API('/credential/self-pass.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.onView() )
    }

    render = () => {
        
        return (
            <Row className="page" gutter={[16, 16]} align={'middle'}>
                { this.state._loading ? <Col span={24}><center><Spin /></center></Col> : (
                    <>
                        <Col md={12} xs={24}>
                            <Card size="small">
                                <Row align='middle' justify='center' style={{flexDirection: 'column'}}>
                                    <Avatar size={130} style={{marginBottom: 5}} src={this.state._user?.AVATAR} />
                                    <Upload maxCount={1} showUploadList={false} accept="image/jpg,image/jpeg,image/png" action={getUPLOADAPI} onChange={this.onChangePic} className={`admin-profile-upload`}>
                                        <Button shape="circle" className="btn-nav" type="default"><IoCameraOutline size={20} /></Button>
                                    </Upload>
                                    <Typography.Paragraph editable={{ tooltip: false, onChange: this.onChangeName }} className="profile-text">{this.state._user?.NAME}</Typography.Paragraph>
                                    { getType() === 'A' ? null : <Tag color="var(--primary)">{this.state?._user?.DES_DEPTO}</Tag> }
                                    { getType() === 'A' ? null : <Typography className="profile-text">Matrícula: {this.state._user?.COLABORADOR_ID ? this.state._user?.COLABORADOR_ID : '-'}</Typography> }
                                    <Divider style={{marginBottom: 15, marginTop: 5}} />
                                    <Form onFinish={this.onSend} layout="horizontal">
                                        <Form.Item label="Senha atual" name="OLD_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input type="password"/>
                                        </Form.Item>
                                        <Form.Item label="Nova senha" name="NEW_PASSWORD" rules={[{required: true, message: 'Campo obrigatório!'}]}>
                                            <Input type="password"/>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button shape="round" htmlType="submit" className="btn-primary" block type="primary">Alterar Senha</Button>
                                        </Form.Item>
                                    </Form>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={12} xs={24}>
                            <Row gutter={[16, 16]}>
                                { getType() !== 'A' ? ( 
                                    <Col span={24}>
                                        <Link to="meusdados"><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">Meu Cadastro</Typography></Button></Link>
                                    </Col>
                                ): null }
                                <Col span={24}>
                                    <Link to="sobreapp"><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">Sobre o Aplicativo</Typography></Button></Link>
                                </Col>
                                <Col span={24}>
                                    <Link to="termos"><Button type="link" block style={{textAlign: 'left'}}><Typography className="profile-link">Termos de Uso</Typography></Button></Link>
                                </Col>
                            </Row>
                        </Col>
                    </>
                ) }
            </Row>
        )
    }

}

export default Perfil;