import React from "react";
import { Alert, Button, Col, Input, Radio, Row, Spin, Typography, message } from "antd";
import { POST_API, POST_CATCH, getToken } from "../../services/api";

class TreinamentoQuestion extends React.Component {

    state = {
        loading: false,
        answer: this.props.verify,
        request: false
    }

    onSend = () => {
        this.setState({loading: true})
        POST_API('/training_question_credential/save.php', { token: getToken(), master: JSON.stringify(this.state.answer), question: this.props.rows[0].TRAINING_CLASS_ID }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({request: true})
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({loading: false }))
    }

    render = () => {
        return (
            <Row gutter={[8,8]}>
                <Col className="main-main" span={24} style={{padding: 10}}>
                    { this.props.screen ? <center><Spin /></center> : (
                        <Row gutter={[16,16]}>
                            { Object.keys(this.props.verify).length > 0 || this.state.request ? <Col span={24}><Alert type="success" message="Questionário já foi respondido, por favor aguarde a correção para receber sua nota!" /></Col> : null }
                            { this.props.rows.map((v, i) => (
                                <Col span={24} key={i}>
                                    <Typography className="tr-quest-title"><span>{i+1}.</span> {v.ASK}</Typography>
                                    { v.TYPE_ASK === 'ESCRITA' ? (
                                        <Input.TextArea defaultValue={this.props.verify?.[`Q${v.ID}`]} style={{resize: 'none'}} disabled={Object.keys(this.props.verify).length > 0 || this.state.request} rows={3} value={this.state.answer?.[`Q${v.ID}`]} onChange={(value) => this.setState({ answer: { ...this.state.answer, [`Q${v.ID}`]: value.target.value } })} />
                                    ) : (
                                        <Radio.Group defaultValue={this.props.verify?.[`Q${v.ID}`]} disabled={Object.keys(this.props.verify).length > 0 || this.state.request} value={this.state.answer?.[`Q${v.ID}`]} onChange={(value) => this.setState({ answer: { ...this.state.answer, [`Q${v.ID}`]: value.target.value } })} >
                                            { v.OPTIONS_ASK.map((v1, i1) => <Radio style={{width: '100%'}} value={v1} key={i1}>{v1}</Radio>) }
                                        </Radio.Group>
                                    ) }
                                </Col>
                            )) }
                            <Col span={24}>
                                { Object.keys(this.props.verify).length === 0 ? <Button loading={this.state.loading} onClick={this.onSend} htmlType="submit" type="primary" shape="round" style={{float: 'right'}}>Salvar</Button> : null }
                            </Col>
                        </Row>
                    ) }
                </Col>
            </Row>
        )
    }

}

export default TreinamentoQuestion;