import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Main from "./pages/Main";
import Home from "./pages/Home";
import Perfil from "./pages/Perfil";
import Termos from "./pages/Termos";
import SobreApp from "./pages/SobreApp";
import NossaEmpresa from "./pages/NossaEmpresa";
import Cadastro from "./pages/Cadastro";
import Timeline from "./pages/Timeline";
import First from "./pages/First";
import Confirm from "./pages/Confirm";
import Ferias from "./pages/Ferias";
import Holerite from "./pages/Holerite";
import Dashboard from "./pages/Dashboard";
import Usuarios from "./pages/Usuarios";
import UsuarioForm from "./pages/UsuariosForm";
import TimelineEdit from "./pages/TimelineEdit";
import Info from "./pages/Info";
import ConfFaqList from "./pages/FAQ/List";
import ConfFaqForm from "./pages/FAQ/Form";
import ConfPageList from "./pages/Page/List";
import ConfPageForm from "./pages/Page/Form";
import FAQ from "./pages/FAQ/FAQ";
import PageLoad from "./pages/PageLoad";
import ConfPagePage from "./pages/Page/Page";
import SetorList from "./pages/Setor/List";
import SetorForm from "./pages/Setor/Form";
import TreinamentoList from "./pages/Treinamento/List";
import TreinamentoForm from "./pages/Treinamento/Form";
import TreinamentoClass from "./pages/Treinamento/Class";
import TreinamentoFormAula from "./pages/Treinamento/FormAula";
import TreinamentoFormQuest from "./pages/Treinamento/FormQuest";

class AppStack extends React.Component {

    render = () => {

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<Login />} />
                    <Route path="first" element={<First />} />
                    <Route path="confirm/:id" element={<Confirm />} />
                    <Route path="forgot" element={<Forgot />} />
                    <Route path="dashboard" element={<Main />}>
                        <Route path="" element={<Dashboard />} />
                        <Route path="users" element={<Usuarios />} />
                        <Route path="users/:id" element={<UsuarioForm />} />
                        <Route path="setor" element={<SetorList type="list" />} />
                        <Route path="setor/add" element={<SetorForm type="add" />} />
                        <Route path="setor/:id" element={<SetorForm type="edit" />} />
                        <Route path="consulta" element={<Home />} />
                        <Route path="ferias" element={<Ferias />} />
                        <Route path="holerite" element={<Holerite />} />
                        <Route path="timeline" element={<Outlet />}>
                            <Route path="" element={<Timeline />} />
                            <Route path=":id" element={<TimelineEdit />} />
                        </Route>
                        <Route path="info" element={<Outlet />}>
                            <Route path="" element={<Info page="P" />} />
                            <Route path="faq" element={<FAQ />} />
                            <Route path="conffaq" element={<Outlet />}>
                                <Route path="" element={<ConfFaqList type="list" />} />
                                <Route path="add" element={<ConfFaqForm type="add" />} />
                                <Route path=":id" element={<ConfFaqForm type="edit" />} />
                            </Route>
                            <Route path="confpag" element={<Outlet />}>
                                <Route path="" element={<ConfPageList type="list" page="P" />} />
                                <Route path="add" element={<ConfPageForm type="add" page="P" />} />
                                <Route path=":id" element={<ConfPageForm type="edit" page="P" />} />
                                <Route path=":id/page" element={<ConfPagePage type="edit" page="P" />} />
                            </Route>
                            <Route path=":id" element={<PageLoad />} />
                        </Route>
                        <Route path="treinamento" element={<Outlet />}>
                            <Route path="" element={<TreinamentoList />} />
                            <Route path="add" element={<TreinamentoForm type="add" />} />
                            <Route path=":id/edit" element={<TreinamentoForm type="edit" />} />
                            <Route path=":id/class" element={<Outlet />}>
                                <Route path="" element={<TreinamentoClass />} />
                                <Route path="aula/:id/edit" element={<TreinamentoFormAula type="edit" />} />
                                <Route path="aula/add" element={<TreinamentoFormAula type="add" />} />
                                <Route path="quest/:id/edit" element={<TreinamentoFormQuest type="edit" />} />
                                <Route path="quest/add" element={<TreinamentoFormQuest type="add" />} />
                            </Route>
                            <Route path="conf" element={<Outlet />}>
                                <Route path="" element={<ConfPageList type="list" page="T" />} />
                                <Route path="add" element={<ConfPageForm type="add" page="T" />} />
                                <Route path=":id" element={<ConfPageForm type="edit" page="T" />} />
                                <Route path=":id/page" element={<ConfPagePage type="edit" page="T" />} />
                            </Route>
                            <Route path=":id" element={<PageLoad />} />
                        </Route>
                        <Route path="profile" element={<Outlet />}>
                            <Route path="" element={<Perfil />} />
                            <Route path="termos" element={<Termos />} />
                            <Route path="sobreapp" element={<SobreApp />} />
                            <Route path="meusdados" element={<Cadastro />} />
                            <Route path="nossaempresa" element={<NossaEmpresa />} />
                            <Route path=":id" element={<UsuarioForm />} />
                        </Route>
                        <Route path="*" element={null} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        )
    }

}

export default AppStack