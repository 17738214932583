import React from "react";
import { Layout, Card, Image, Typography, Form, Input, Button, message } from "antd";
import { Link, Navigate } from 'react-router-dom';

import logo from '../assets/slog1.png';
import { POST_API, POST_CATCH, setToken, setType } from "../services/api";

class Login extends React.Component {

    state = { _navigate: false, loading: false }

    onSend = (values) => {
        this.setState({loading: true})
        values.TYPE = "P,C,A";
        POST_API('/credential/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success(res.msg)
                setToken(res.token)
                setType(res.type)
                this.setState({_navigate: true})
            } else {
                message.warning(res.msg)
            }
        }).catch(() => { POST_CATCH() }).finally(() => {
            this.setState({loading: false})
        })
        
    }

    render = () => {

        if (this.state._navigate) return ( <Navigate to='dashboard' />)

        return (
            <Layout.Content className="login-content">
                <Card className="login-card">
                    <center><Image src={logo} preview={false} className="login-card-image"/></center>
                    <Typography className="login-card-title">Bem-vindo à plataforma</Typography>
                    <Form layout="vertical" onFinish={this.onSend}>
                        <Form.Item label="Matrícula ou e-mail" name="LOGIN" className="login-form-item">
                            <Input size="large" placeholder="Número da matrícula ou e-mail" />
                        </Form.Item>
                        <Form.Item label="Senha" name="PASSWORD" className="login-form-item">
                            <Input.Password size="large" placeholder="Preencha a senha" />
                        </Form.Item>
                        <Form.Item className="login-form-item">
                            <Button htmlType="submit" type="primary" block className="btn-primary" shape="round" loading={this.state.loading}>ENTRAR</Button>
                            <Link to="forgot"><Button type="link" block className="btn-link" shape="round" size="small">Esqueceu a senha?</Button></Link>
                            <Link to="first"><Button type="link" block className="btn-link" shape="round" size="small">Primeiro acesso</Button></Link>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout.Content>
        )
    }

}

export default Login;