import React from "react";
import { Card, Col, Modal, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken, getType } from "../services/api";


class Home extends React.Component {

    state = { _navigate: false, _user: null }

    componentDidMount = () => {

        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (!res.return) {
                    this.setState({_navigate: true})
                } else {
                    this.setState({_user: res.user})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        } else {
            this.setState({_navigate: true})
        }

    }

    render = () => {
        return (
            <Row className="page" gutter={[8,8]}>
                <Col md={12} xs={24}>
                    <Link to='/dashboard/holerite'>
                        <Card size="small" hoverable title={<Typography className="home-title">Holerites</Typography>}>
                            <Typography>Consulta Histórico de pagamentos disponíveis nos últimos 12 meses.</Typography>
                        </Card>
                    </Link>
                </Col>
                {/* <Col md={12} xs={24}>
                    <Card size="small" onClick={() => Modal.info({ title: 'Atenção', 'content': 'Por favor, contatar a liderança' })} hoverable title={<Typography className="home-title">Férias</Typography>}>
                        <Typography>Consulte suas informações de férias, período aquisitivo e saldo.</Typography>
                    </Card>
                </Col>
                <Col md={12} xs={24}>
                    <Card size="small" onClick={() => Modal.info({ title: 'Atenção', 'content': 'Por favor, contatar a equipe RH' })} hoverable title={<Typography className="home-title">Informe de Rendimentos</Typography>}>
                        <Typography>Consulta históricos para declaração de IR</Typography>
                    </Card>
                </Col> */}
                <Col md={12} xs={24}>
                    <Link to="/dashboard/timeline">
                        <Card size="small" hoverable title={<Typography className="home-title">Abrir timeline</Typography>}>
                            <Typography>Ver publicações</Typography>
                        </Card>
                    </Link>
                </Col>
            </Row>
        )
    }

}

export default Home;