import React from "react";
import { Button, Card, Col, Input, Row, Spin, Typography, message } from "antd";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { POST_API, POST_CATCH, getToken, getType } from "../services/api";

class Ferias extends React.Component {

    state = { _loading: true, data: null, _user: null, ref: null, mat: null, load: false }

    componentDidMount = () => {

        if (getToken()) {
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_user: res.user, mat: res.user.COLABORADOR_ID > 0 ? res.user.COLABORADOR_ID : ''})
                }
            }).catch(() => POST_CATCH()).finally(() => {
                this.setState({_loading: false})
            })
        }

    }

    onSearch = () => {
        this.setState({load: true})
        
        POST_API('/credential/ferias.php', { token: getToken(), master: JSON.stringify({ COD_COLABORADOR: this.state.mat, REF: this.state.ref }) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({data: res.data})
            } else {
                message.warning(res.msg)
            }
        }).catch(() => POST_CATCH()).finally(() => {
            this.setState({load: false})
        })
    }

    onDownload = () => {
        window.open('https://apprh.adsolucoestecnologia.com.br/services/php/report/ferias.php?COD_COLABORADOR='+this.state.mat);
    }

    render = () => {
        return (
            <Row className="page" gutter={[8,8]}>
                { this.state._loading ? <Col span={24}><center><Spin /></center></Col> : (
                    <>
                        <Col span={24}>
                            <Card size="small">
                                <Row align={'bottom'} gutter={[8,8]}>
                                    <Col xs={24} md={20}>
                                        <Typography>Numero Matrícula</Typography>
                                        <Input disabled={getType() === 'C' || getType() === 'MK'} value={this.state.mat} onChange={(v) => this.setState({mat: v.target.value})} />
                                    </Col>
                                    <Col xs={24} md={4}>
                                        <Button type="primary" block shape="round" onClick={this.onSearch}>Procurar</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        { this.state.load ? <Col span={24}><center><Spin /></center></Col> : (
                            <>
                                { this.state.data !== null ?
                                    <Col span={24}>
                                        <Card size="small">
                                            <Row justify={'space-between'}>
                                                <Typography className="home-title"><b>Aviso de Férias</b></Typography>
                                                <Button type="link" onClick={this.onDownload}><IoCloudDownloadOutline size={26} /></Button>
                                            </Row>
                                        </Card>
                                    </Col>
                                : <Col span={24}><Card size="small"><center><Typography>Férias não encontrada</Typography></center></Card></Col> }
                            </>
                        )}
                    </>
                ) }
            </Row>
        )
    }

}

export default Ferias;