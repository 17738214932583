import React from "react";
import { Button, Card, Col, Dropdown, Input, Modal, Pagination, Popover, Progress, Rate, Result, Row, Skeleton, Spin, Tabs, Typography, message } from "antd";
import { Link } from "react-router-dom";
import { POST_API, POST_CATCH, getToken, getType } from "../../services/api";

import './styles.css';
import { IoEllipsisVertical } from "react-icons/io5";
import { RiPresentationLine } from "react-icons/ri";
import { ExclamationCircleOutlined } from '@ant-design/icons';

class TreinamentoList extends React.Component {

    state = {
        loadTabs: true,
        tabs: [],
        key: 'ggchtgch',
        loadData: true,
        cursos: [],
        search: '',
        pagination: {
            current: 1,
            total: 0,
            limit: 10
        }
    }

    componentDidMount = () => {
        this.onLoadTab().finally(() => {
            this.onRenderData()
        })
    }

    onRenderData = ( load = true) => {
        if (load) this.setState({loadData: true })
        setTimeout(async () => {
            POST_API('/training/search.php', { token: getToken(), pagination: JSON.stringify(this.state.pagination), filter: JSON.stringify({ COD_DEPTO: this.state.key }), search: this.state.search, type: 'list' }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        cursos: res.data,
                        pagination: { ...this.state.pagination, total: res.summary.QTDE }
                    })
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => POST_CATCH()).finally(() => this.setState({loadData: false }))
        }, 500);
    }

    onLoadTab = async () => {
        POST_API('/credential/depto.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({ tabs: res.data, key: res.data[0].key })
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({loadTabs: false }))
    }

    onChangeTab = (key) => {
        this.setState({key: key, loadData: true})
        setTimeout(() => {
            this.onRenderData()
        }, 500);
    }

    onSearch = () => {
        this.onRenderData()
    }

    onAction = (ID, type, title) => {
        var self = this
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            onOk() {
                POST_API('/training/del.php', { token: getToken(), ID: ID, type: type, selected: JSON.stringify(self.state.table_select) }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        if (type === 'del-select' || type === 'rec-select') self.setState({table_select: []})
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {POST_CATCH()   }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
        
    }

    onRate = (item, value) => {
        POST_API('/training/rate.php', { token: getToken(), ID: item.ID, RATE: value }).then(rs => rs.json()).then(res => {
            if (res.return) { 
                this.onRenderData(false)
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => POST_CATCH()).finally(() => this.setState({loadTabs: false }))
    }

    render = () => {

        return (
            <Row className="page-content" gutter={[16, 16]}>
                <Col span={24}>
                    <Card size="small">
                        <Row justify={'space-between'} style={{marginBottom: 10}}>
                            <Col>
                                <Typography className="tr-title"> { getType() === 'A' || getType() === 'MK' ? 'Gestão de Treinamentos' : 'Treinamentos' } </Typography>
                            </Col>
                            <Col>
                                <Row gutter={[4,4]} justify={'end'}>
                                    <Col><Input placeholder="Pesquisar..." onChange={(e) => this.setState({search: e.target.value})} onPressEnter={this.onSearch} /></Col>
                                    { getType() === 'A' || getType() === 'MK' ? <Col><Link to={'add'}><Button type="primary" shape="round">Adicionar</Button></Link></Col> : null }
                                </Row>
                            </Col>
                        </Row>
                        { this.state.loadTabs ? <Skeleton.Button block active={true} /> : <Tabs className="tr-tab" items={this.state.tabs} onChange={this.onChangeTab} /> }
                    </Card>
                </Col>
                { this.state.loadData ? 
                    <Col span={24}><Card size="small"><Result icon={<Spin />} title="Carregando" subTitle="Por favor, aguarde!" /></Card></Col> 
                : this.state.cursos.length > 0 ? this.state.cursos.map((v, i) => (
                    <Col xs={24} md={6} key={i}>
                        <Card size='small' hoverable>
                            <Typography className="tr-card-title" title={`${v.TITLE}`}>{v.TITLE}</Typography>
                            <Typography className="tr-card-create" title={`Criado por ${v.CREATE_BY}`}>Criado por {v.CREATE_BY}</Typography>
                            <Progress percent={Number(v.PERCENT)}/>
                            <Row gutter={[4,4]} align="bottom" justify="space-between">
                                <Col>
                                    <Row gutter={[4,4]} align="bottom">
                                        <Col><Rate value={v.RATE} onChange={(e) => this.onRate(v, e)} /></Col>
                                    </Row>
                                </Col>
                                <Col style={{height: 20}}><Popover content="Abrir treinamento"><Link to={v.ID+'/class'}><RiPresentationLine size={20} className="tr-button"/></Link></Popover></Col>
                            </Row>
                            { getType() === 'A' || getType() === 'MK' ? 
                                <Dropdown menu={{ items: [ {label: <Link to={v.ID+'/edit'}>Editar</Link>, key: '1'}, {label: 'Deletar', key: '2', onClick: () => this.onAction(v.ID, 'del', 'Excluir registro?')} ] }} placement="bottomCenter">
                                    <IoEllipsisVertical className="tr-card-op" />
                                </Dropdown>
                            : null }
                        </Card>
                    </Col>
                )) : (
                    <Col span={24}><Card size="small"><Result status='info' title="Curso não encontrado" subTitle="Desculpe, mas não conseguimos encontrar seu curso" /></Card></Col>
                ) }
                <Col span={24}><center><Pagination defaultCurrent={this.state.pagination.current} current={this.state.pagination.current} total={this.state.pagination.total} pageSize={this.state.pagination.limit} /></center></Col>
            </Row>
        )
        
    }

}

export default TreinamentoList;